.App {
  text-align: center;
}

nav {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
}

.post {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  padding: 16px;
  font-weight: bold;
  font-size: 35px;
}

.username {
  padding: 16px;
  padding-top: 0px;
  font-style: italic;
  font-size: 30px;
}

.contents {
  padding: 24px;
  padding-top: 0;
  font-size: 25px;
}

nav p {
  border: #61dafb;
  border-radius: 1;
  flex: 1;
  align-content: center;
  display: flex;
  justify-content: center;
}

div form {
  display: flex;
  flex-direction: column;
}

div form * {
  justify-content: center;
  margin: auto;
  padding: 16px;
  width: 50vw;
}

button {
  margin-top: 10px !important;
}

nav p a:link
{
   color: #fb3f00;
   text-decoration: none;
}
nav p a:visited
{
   color: #fb3f00;
   text-decoration: none;
}
nav p a:hover
{
   color: #fb3f00;
   text-decoration: none;
}
nav p a:active
{
   color: #fb3f00;
   text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
